import { React, useContext, useEffect, useState } from "react";
import "./Header.css";
import { Col, Container, Nav, NavDropdown, Navbar, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import { faShoppingCart, faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import { Context } from "../../../utils/context";
import Cookies from 'js-cookie';

import { GetAppSetup, GetWebHeader } from "../../../utils/apis/master/Master";
import ErrorModal from "../../Common_Component/ErrorModalDynamic/ErrorModal"
import LogoutModal from "../../Common_modal/LogoutModal/LogoutModal";

const Header = () => {
  const { cartCount, signin, setSignin, sellerId, domainError, setDomainError,
    domainModal, setDomainModal, IMG_URL, getDomainData } = useContext(Context);
  const [logoutshow, setLogoutShow] = useState(false);


  const navigate = useNavigate();



  // const handleLogout = () => {
  //   Cookies.remove('outer_website_security');
  //   setSignin(false);
  //   navigate('/');
  // }

  const [headers, setHeaders] = useState([]);
  const getHeader = async () => {
    const res = await GetWebHeader(sellerId);
    if (res?.success) {
      setHeaders(res?.data);
    }
  }

  const updateFavicon = (imageUrl) => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = imageUrl;
    document.getElementsByTagName('head')[0].appendChild(link);
  };

  const [appSetup, setAppSetup] = useState();
  const getAppSetup = async () => {
    const res = await GetAppSetup(sellerId);
    if (res?.success) {
      setAppSetup(res?.data);
      updateFavicon(IMG_URL + res?.data?.image);
    }

  }

  useEffect(() => {
    getHeader();
    getAppSetup();
  }, [sellerId]);
  return (
    <>
      <section className="header-section" style={{
        background: appSetup?.header_color
      }}>
        <Container fluid className="p-0">
          <Container className="p-0">
            <div className="header-main roboto-family">
              <Navbar collapseOnSelect expand="lg" className=" p-0 m-0">
                <Navbar.Brand className="header-img">
                  <Link to={"/"}>
                    <img
                      className="header-logo"
                      src={
                        IMG_URL +
                        appSetup?.image
                      }
                      alt="Logo"
                    />
                  </Link>
                </Navbar.Brand>

                <div className="mobile_view_icon_holder">
                  <Nav className="navbMainTwo ">
                    <div className="xs-view">
                      <div className="mobile_view_icon ">
                        <Nav.Link
                          as={Link}
                          to="/product-cart-inner"
                          className="header-lists2 "
                        >
                          <FontAwesomeIcon icon={faShoppingCart} />
                        </Nav.Link>
                        <Nav.Link
                          as={Link}
                          to="/wishlist"
                          className="header-lists2 "
                        >
                          <FontAwesomeIcon icon={farHeart} />
                        </Nav.Link>

                        <Nav.Link
                          as={Link}
                          to="/login"
                          className="header-lists2 "
                        >
                          <FontAwesomeIcon icon={faUser} />
                        </Nav.Link>
                      </div>
                    </div>
                  </Nav>
                </div>
                <div className="mobile_view_icon_holder">
                  <Nav className="navbMainTwo ">
                    <Nav.Link className="header-lists2 ">
                      <div className="SearchinputMain">
                        <Form.Control type="email" placeholder="Search here" />
                        <FontAwesomeIcon
                          icon={faSearch}
                          className="searchicon"
                        />
                      </div>
                    </Nav.Link>
                    <div className="lg_view">
                      <div className="mobile_view_icon ">
                        <Nav.Link
                          as={Link}
                          to="/product-cart-inner"
                          className="header-lists2 "
                        >
                          <FontAwesomeIcon icon={faShoppingCart} />
                        </Nav.Link>
                        <Nav.Link
                          as={Link}
                          to="/wishlist"
                          className="header-lists2 "
                        >
                          <FontAwesomeIcon icon={farHeart} />
                        </Nav.Link>

                        <Nav.Link
                          as={Link}
                          to="/login"
                          className="header-lists2 "
                        >
                          <FontAwesomeIcon icon={faUser} />
                        </Nav.Link>
                      </div>
                    </div>
                  </Nav>
                </div>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto navbMainOne">
                    {headers.length > 0 ? (
                      headers.map((value, index) => (
                        <>
                          {value.name === 'Home' && (
                            <Link to={"/"} className="header-lists">
                              <Nav.Link href="/" className="header-lists" style={{
                                color: appSetup?.header_font_color
                              }}>
                                Home
                              </Nav.Link>
                            </Link>
                          )}
                          {value.name === 'About Us' && (
                            <Link to={"/about-us"} className="header-lists">
                              <Nav.Link href="/about-us" className="header-lists" style={{
                                color: appSetup?.header_font_color
                              }}>
                                About Us
                              </Nav.Link>
                            </Link>
                          )}
                          {value.name === 'Contact Us' && (
                            <Link to={"/contact-us"} className="header-lists">
                              <Nav.Link href="/contact-us" className="header-lists" style={{
                                color: appSetup?.header_font_color
                              }}>
                                Contact Us
                              </Nav.Link>
                            </Link>
                          )}
                          {value.name === 'Blog' && (
                            <Link to={"/blog"} className="header-lists">
                              <Nav.Link href="/blog" className="header-lists" style={{
                                color: appSetup?.header_font_color
                              }}>
                                Blog
                              </Nav.Link>
                            </Link>
                          )}
                          {value.name === 'FAQs' && (
                            <Link to={"/faq"} className="header-lists">
                              <Nav.Link href="/faq" className="header-lists" style={{
                                color: appSetup?.header_font_color
                              }}>
                                FAQ
                              </Nav.Link>
                            </Link>
                          )}
                        </>
                      ))
                    ) : (
                      <>
                        <Link to={"/about-us"} className="header-lists">
                          <Nav.Link href="/about-us" className="header-lists" style={{
                            color: appSetup?.header_font_color
                          }}>
                            About Us
                          </Nav.Link>
                        </Link>
                        <Link to={"/contact-us"} className="header-lists">
                          <Nav.Link href="/contact-us" className="header-lists" style={{
                            color: appSetup?.header_font_color
                          }}>
                            Contact Us
                          </Nav.Link>
                        </Link>
                        <Link to={"/blog"} className="header-lists">
                          <Nav.Link href="/blog" className="header-lists" style={{
                            color: appSetup?.header_font_color
                          }}>
                            Blog
                          </Nav.Link>
                        </Link>
                        <Link to={"/faq"} className="header-lists">
                          <Nav.Link href="/faq" className="header-lists" style={{
                            color: appSetup?.header_font_color
                          }}>
                            FAQ
                          </Nav.Link>
                        </Link>
                      </>
                    )}




                  </Nav>

                  {/* <Nav className="navbMainTwo">
                    <Nav.Link className="header-lists2 ">
                      <div className="SearchinputMain">
                        <Form.Control type="email" placeholder="Search here" />
                        <FontAwesomeIcon
                          icon={faSearch}
                          className="searchicon"
                        />
                      </div>
                    </Nav.Link>

                    <Nav.Link as={Link} to="/product-cart-inner" className="header-lists2 ">
                      <FontAwesomeIcon icon={faShoppingCart} />
                    </Nav.Link>
                    <Nav.Link  as={Link} to="/productdescription" className="header-lists2 ">
                      <FontAwesomeIcon icon={farHeart} />
                    </Nav.Link>

                    <Nav.Link as={Link} to="/login" className="header-lists2 ">
                      <FontAwesomeIcon icon={faUser} />
                    </Nav.Link>
                  </Nav> */}

                  <Nav className="navbMainTwo desktop_view_icon_holder">
                    <Nav.Link className="header-lists2 ">
                      <div className="SearchinputMain">
                        <Form.Control type="email" placeholder="Search here" />
                        <FontAwesomeIcon
                          icon={faSearch}
                          className="searchicon"

                        />
                      </div>
                    </Nav.Link>
                    <div className="mobile_view_icon">
                      <Nav.Link
                        as={Link}
                        to="/product-cart-inner"
                        className="header-lists2 "
                      >
                        <FontAwesomeIcon icon={faShoppingCart} style={{
                          color: appSetup?.header_font_color
                        }} />
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        to="/wishlist"
                        className="header-lists2 "
                      >
                        <FontAwesomeIcon icon={farHeart} style={{
                          color: appSetup?.header_font_color
                        }} />
                      </Nav.Link>

                      {/* <Nav.Link
                        as={Link}
                        to="/my-account"
                        className="header-lists2 "
                      >
                        <FontAwesomeIcon icon={faUser} />
                      </Nav.Link>

                      <Nav.Link
                        as={Link}
                        to="/login"
                        className="header-lists2 "
                      >
                        Login
                      </Nav.Link>
                        to="/login"
                        className="header-lists2"
                      >
                        <FontAwesomeIcon icon={faUser} />
                      </Nav.Link> */}

                      {signin ? (
                        <>
                          <Nav.Link
                            as={Link}
                            to="/my-account"
                            className="header-lists2"
                          >
                            <FontAwesomeIcon icon={faUser} style={{
                              color: appSetup?.header_font_color
                            }} />
                          </Nav.Link>
                          <Nav.Link to="/login" className="header-lists2 ">
                            <Nav.Link as={Link} className="login" onClick={() => setLogoutShow(true)} style={{
                              color: appSetup?.header_font_color
                            }}>
                              Logout
                            </Nav.Link>
                          </Nav.Link>
                        </>
                      ) : (
                        <>

                          <Nav.Link as={Link} to="/login" className="header-lists2" style={{
                            color: appSetup?.header_font_color
                          }}>
                            Login
                          </Nav.Link>
                        </>
                      )}
                    </div>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </Container >
        </Container >
      </section >
      <LogoutModal
        show={logoutshow}
        onHide={() => setLogoutShow(false)}
        title="Are you sure you want to logout?"
        btnname="Logout"
        userType={'seller'}
      />

      <ErrorModal show={domainModal} onHide={() => setDomainModal(false)} heading={domainError} text={""} />
    </>
  );
};

export default Header;
