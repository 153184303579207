import React, { useState, useEffect, useContext } from "react";
import "./Login.css";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Buttons from "../Common_Component/Buttons/Buttons";
import Register from "./Register/Register";
import ResetPassWord from "./ResetPassWord/ResetPassWord";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../utils/context";
import { userLogin } from "../../utils/apis/login/Login";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import Cookies from "js-cookie";
import classNames from "classnames";
// import Common_Button from "../../Common-Component/Common_Button/Common_Button";

import Success_Modalss from "../Common_Component/Success_Modal/Success_Modalss";
import { GetAppSetup } from "../../utils/apis/master/Master";

function Login() {
  const { IMG_URL, setSignin, sellerDomain, sellerId } = useContext(Context);
  const navigate = useNavigate();
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(false); // State variable for showing register form
  const [showPassword, setShowPassword] = useState(false);
  const [iconOne, setIconOne] = useState(faEye);

  const [appSetup, setAppSetup] = useState();
  const getAppSetup = async () => {
    const res = await GetAppSetup(sellerId);
    if (res?.success) {
      setAppSetup(res?.data);
    }

  }

  useEffect(() => {
    getAppSetup();
  }, [sellerId]);

  // Function to handle click event on "Create account?"
  const handleCreateAccountClick = () => {
    setShowRegisterForm(true);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setIconOne(showPassword ? faEyeSlash : faEye);
  };



  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const [modalShow, setModalShow] = useState(false);
  const onSubmit = async (data) => {
    data.domain_id = sellerDomain;
    const res = await userLogin(data);
    if (res?.success) {
      await Cookies.set("outer_website_security", res?.data?.token);
      await setSignin(true);
      reset();

      // await loginCart();

      setModalShow(true);
      setTimeout(() => {

        setModalShow(false);
        navigate('/');
      }, 3000);
    } else {
      if (res?.message) {
        setError("email_or_contact", { message: res?.message });
        setError("password", "");
      }
    }
  };

  return (
    <>
      {!showForgetPassword && !showRegisterForm && (
        <section className="login-section">
          <Container>
            <div className="LogIn">
              <div className="row bg-color me-0 ms-0">
                {/* Login Form */}
                <div className="col-lg-7 p-0 ">
                  <div className="row justify-content-center border-bottom-color me-0 ms-0  ">
                    <div className="col-xl-8  col-lg-9 col-md-9">
                      <div className="loginlogo-div">
                        <img
                          className="loginlogo"
                          src={
                            IMG_URL +
                            appSetup?.image
                          }
                          alt="social_img"
                        />
                      </div>

                      <div className="back_to_span">
                        <Link to={"/"}>
                          <p>Back to Home</p>
                        </Link>
                      </div>

                      <div className="heading-holder">
                        <h4>Login</h4>
                      </div>

                      <div className="log_in_form">
                        <Form>
                          <Form.Group className="mb-3" controlId="">
                            <Form.Control
                              type="text"
                              name="email_or_contact"
                              placeholder="Email / Mobile Number"
                              {...register("email_or_contact", {
                                required: "Credentials required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.email_or_contact,
                                "is-valid": getValues("email_or_contact"),
                              })}
                            />
                          </Form.Group>
                          {errors?.email_or_contact && (
                            <sup className="text-danger">
                              {errors?.email_or_contact?.message}
                            </sup>
                          )}

                          <Form.Group className="mb-3" controlId="">
                            <div className="password-field-holder">
                              <Form.Control
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter Password*"
                                name="password"
                                {...register("password", {
                                  required: "Password is required",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.password,
                                  "is-valid": getValues("password"),
                                })}
                              />
                              <div className="eye-icon-holder ">
                                <FontAwesomeIcon
                                  icon={iconOne}
                                  className="eye-icon"
                                  onClick={() =>
                                    togglePasswordVisibility("password")
                                  }
                                />
                              </div>
                            </div>
                            {/* <Link to={"/forget-password"}> */}
                            <Form.Text
                              className="Forgot_password"
                              onClick={() => setShowForgetPassword(true)}
                            >
                              Forgot password?
                            </Form.Text>
                            {/* </Link> */}
                          </Form.Group>

                          <div className="btn-holder">
                            {/* <button className="SubmitBtn" type="submit" text="Login" onClick={handleSubmit(onSubmit)} /> */}
                            <button
                              className="SubmitBtn"
                              type="button"
                              onClick={handleSubmit(onSubmit)}
                            >
                              Login
                            </button>
                          </div>

                          <div className="create_account mb-5">
                            {/* Attach click event handler */}
                            {/* <Link to={"/register-form"}> */}
                            <p onClick={handleCreateAccountClick}>
                              Create account ?
                            </p>
                            {/* </Link> */}
                          </div>
                        </Form>
                        <div className="log_in_with">
                          <p>or login with netpurti</p>
                        </div>

                        <div className="social_img_holder">
                          <img
                            className="social_img"
                            src={
                              IMG_URL +
                            appSetup?.image
                            }
                            alt="social_img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      )}
      <Success_Modalss show={modalShow} onHide={() => setModalShow(false)} texts={'Login SuccessFull'} />
      {showForgetPassword && <ResetPassWord />}
      {showRegisterForm && <Register />}
    </>
  );
}

export default Login;
