import React, { useContext, useEffect, useState } from "react";
import "./Success_Modalss.css";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { Context } from "../../../utils/context";
import { GetAppSetup } from "../../../utils/apis/master/Master";

function Success_Modalss(props) {

  const { IMG_URL, sellerId } = useContext(Context);


  const [appSetup, setAppSetup] = useState();
  const getAppSetup = async () => {
    const res = await GetAppSetup(sellerId);
    if (res?.success) {
      setAppSetup(res?.data);
    }

  }

  useEffect(() => {
    getAppSetup();
  }, [sellerId]);

  return (
    <>
      <section className="successmaodal-section">
        <Modal
          show={props.show}
          onHide={props.onHide}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="succs-modl"
        >
          <div className="modals-top">
            <Modal.Header className="modal-heading">
            </Modal.Header>

            <div className="loginlogomain">
              <img
                className="loglogo"
                src={
                  IMG_URL +
                appSetup?.image
                }
                alt="social_img"
              />
            </div>
          </div>
          <Modal.Body className="modalssbody">
            <p className="modalcontent">
              {props.texts}
            </p>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
}

export default Success_Modalss;
